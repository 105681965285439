/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Penman = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // checkout page
    'checkout': {
      init: function() {
        // console.log('popover');
        // $('[data-toggle="popover"]').popover();
        // $('[data-toggle="tooltip"]').tooltip();
        $('form.checkout').on('click', 'a.terms-link', function(e){
          e.preventDefault();
          $.get(this.href, {},
            function(data){
              var message = $(data).find('.main p');
              var title = $(data).find('.page-header').text();
              // console.log(foo);
              // return;
              bootbox.confirm({
                title: title,
                message: message,
                callback: function(agree) {
                  $('form.checkout').find('input[name=terms]').prop( 'checked', agree );
                }
              });
            }
          );
        });
      }
    },
    // gallery page
    'gallery': {
      init: function() {
        var options = {};
        $('.entry-content .gallery').each(function(){
          var $this = $(this),
              id = $this.attr('id'),
              options = {
                gallery: id,
                gallery_parent_selector: '.entry-content .gallery'
              };
          $this.find('.gallery-icon a').each(function(){
            var $link = $(this);
            $link
              .data('title', $link.find('img').attr('title') )
              .click(function(e){
                e.preventDefault();
                console.log(options);
                $(this).ekkoLightbox(options);
              });
          });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Penman;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
